<template>
  <b-form @submit.prevent="save">
    <validation-observer
      ref="elementForm"
      #default="{ invalid }">
      <b-card>
        <b-card-header>
          <div class="text-primary h2">
            <feather-icon
              icon="StarIcon"
              size="25"
              class="mr-0 mr-sm-50"
              variant="primary" />
            <span class="d-none d-sm-inline">Información Principal</span>
          </div>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
              cols="4"
              md="4">
              <b-form-group label-for="type">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(resourcesElementName + '.object.type')
                }}</span
                ><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.type')"
                  rules="required">
                  <v-select
                    v-model="elementData.type_id"
                    :options="typeList"
                    label="value"
                    value="id"
                    :reduce="(option) => option.id"
                    :placeholder="$t('list.defaultOption')" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Right Col: Table -->
            <b-col
              v-if="isType('NOTIFICATION')"
              cols="4"
              md="4">
              <b-form-group label-for="step">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(resourcesElementName + '.object.step')
                }}</span
                ><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.step')">
                  <v-select
                    v-model="elementData.step_id"
                    :options="stepList"
                    label="value"
                    value="id"
                    :reduce="(option) => option.id"
                    :placeholder="$t('list.defaultOption')" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="isType('REMINDER')"
              cols="4"
              md="4">
              <b-form-group label-for="reminder_type">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(resourcesElementName + '.object.reminder_type')
                }}</span
                ><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.reminder_type')"
                  rules="required">
                  <v-select
                    v-model="elementData.reminder_type_id"
                    :options="reminderTypeList"
                    label="value"
                    value="id"
                    :reduce="(option) => option.id"
                    :placeholder="$t('list.defaultOption')" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="isType('REMINDER')"
              cols="4"
              xl="4">
              <b-form-group label-for="reminder_time">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(resourcesElementName + '.object.reminder_time')
                }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.reminder_time')">
                  <b-form-input
                    id="reminder_time"
                    v-model="elementData.reminder_time"
                    maxlength="100" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <!-- ? REMINDER TIME -->
                <b-tooltip
                  target="reminder_time"
                  :title="
                    $t(resourcesElementName + '.object.reminder_time_tooltip')
                  "
                  triggers="hover"
                  :noninteractive="true"
                  fallback-placement="counterclockwise">
                </b-tooltip>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            v-if="isType('REMINDER')"
            class="mb-1">
            <b-col
              cols="4"
              md="4"
              lg="4">
              <b-form-checkbox
                id="send_email"
                v-model="elementData.send_email">
                <span class="font-weight-bold">{{
                  $t(resourcesElementName + '.object.send_email')
                }}</span>
              </b-form-checkbox>
            </b-col>
            <b-col
              cols="4"
              md="4"
              lg="4">
              <b-form-checkbox
                id="send_sms"
                v-model="elementData.send_sms">
                <span class="font-weight-bold">{{
                  $t(resourcesElementName + '.object.send_sms')
                }}</span>
              </b-form-checkbox>
            </b-col>
            <b-col
              cols="4"
              md="4"
              lg="4">
              <b-form-checkbox
                id="send_whatsapp"
                v-model="elementData.send_whatsapp">
                <span class="font-weight-bold">{{
                  $t(resourcesElementName + '.object.send_whatsapp')
                }}</span>
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              v-if="isType('NOTIFICATION') || elementData.send_email"
              cols="12"
              xl="12">
              <b-form-group label-for="email_subject">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(resourcesElementName + '.object.email_subject')
                }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.email_subject')">
                  <b-form-input
                    id="email_subject"
                    v-model="elementData.email_subject"
                    maxlength="200" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <!-- Right Col: Table -->
            <b-col
              v-if="isType('NOTIFICATION') || elementData.send_email"
              cols="12"
              xl="12">
              <b-form-group label-for="email_content">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(resourcesElementName + '.object.email_content')
                }}</span
                ><span class="text-danger">*</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.email_content')"
                  rules="required">
                  <vue-editor
                    id="email_content"
                    v-model="elementData.email_content" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              v-if="isType('NOTIFICATION') || elementData.send_whatsapp"
              cols="12"
              xl="12">
              <b-form-group label-for="whatsapp_content">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(resourcesElementName + '.object.whatsapp_content')
                }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.whatsapp_content')">
                  <b-form-textarea
                    id="whatsapp_content"
                    v-model="elementData.whatsapp_content"
                    maxlength="2000"
                    rows="5"
                    max-rows="15" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              v-if="isType('NOTIFICATION') || elementData.send_sms"
              cols="12"
              xl="12">
              <b-form-group label-for="sms_content">
                <feather-icon
                  icon="StarIcon"
                  class="mr-75" />
                <span class="font-weight-bold">{{
                  $t(resourcesElementName + '.object.sms_content')
                }}</span>
                <validation-provider
                  #default="{ errors }"
                  :name="$t(resourcesElementName + '.object.sms_content')">
                  <b-form-textarea
                    id="sms_content"
                    v-model="elementData.sms_content"
                    maxlength="300" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-row class="d-flex justify-content-end">
        <b-button
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="router().go(-1)">
          {{ $t('actions.back') }}
        </b-button>
        <b-button
          variant="primary"
          type="submit"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :disabled="invalid">
          {{ $t('actions.save') }}
        </b-button>
      </b-row>
    </validation-observer>
  </b-form>
</template>

<script>
import {
  BTooltip,
  BFormTextarea,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BForm,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { VueEditor } from 'vue2-editor'
import router from '@/router'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    VueEditor,
    BTooltip,
    BFormTextarea,
    BFormCheckbox,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BForm,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    newElement: {
      type: Boolean,
      required: true,
    },
    resourcesElementName: {
      type: String,
      required: true,
    },
    reminderTypeList: {
      type: Array,
      required: true,
    },
    stepList: {
      type: Array,
      required: true,
    },
    typeList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  watch: {
    elementLogo(newValue, oldValue) {
      this.logo = newValue
    },
  },
  methods: {
    router() {
      return router
    },
    isType(type) {
      return (
        this.elementData.type_id &&
        this.elementData.type_id ===
          this.typeList.find((element) => type === element.key).id
      )
    },
    save() {
      this.$refs.elementForm.validate().then((success) => {
        if (success) {
          console.log(this.elementData)
          if (this.isType('NOTIFICATION')) {
            this.elementData.reminder_type_id = null
            this.elementData.reminder_time = null
          } else {
            this.elementData.step_id = null
          }
          console.log(this.elementData)
          this.$parent.save(this.elementData)
        }
      })
    },
  },
}
</script>

<style></style>
